import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import Section from "../elements/Section"
import Container from "../elements/Container"
import globalVariables from "../globalVariables"
import Card from "../Home/Blog/Card"
import Pagination from "../elements/Pagination"
import {
  getItemsperPage,
  itemsPerPage,
} from "../elements/Pagination/getItemsPerPage.js"

const Cards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`
const IndexPage = styled.div`
  display: none;
`

const BlogList = ({ data, articles, numberPage, handleClickPage }) => {
  const allItems = getItemsperPage(data)

  return (
    <Section padding="45px 0" paddingMobile="30px 0" id="articles-liste">
      <Container>
        <Cards>
          {allItems &&
            allItems[numberPage] &&
            allItems[numberPage].map((article, index) => (
              <Card data={article} prefix="/blog" key={index} />
            ))}
        </Cards>
        {data.length > itemsPerPage && (
          <Pagination
            allItems={allItems}
            handleClickPage={handleClickPage}
            numberPage={numberPage}
          />
        )}
        <IndexPage>
          {articles.map((item, index) => (
            <Link to={`/blog/${item.node.path}`} key={index}>
              {item.node.acf.titre_article}
            </Link>
          ))}
        </IndexPage>
      </Container>
    </Section>
  )
}

export default BlogList
