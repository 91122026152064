export const itemsPerPage = 9

export const getItemsperPage = items =>
  items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / itemsPerPage)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)
    return resultArray
  }, [])
