import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Contact from "../components/Home/Contact"
import Filter from "../components/Blog/Filter"
import BlogList from "../components/Blog"
import { getDateFormat } from "../utils/getDateFormat"
import BannerV2 from "../components/elements/BannersV2/BannerV2"

const ResultText = styled.p`
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`

const bannerLeftCardContent = {
  mainContent: `Retrouvez toute l'actualité du marketing digital mais également des dossiers et des articles
    rédigés par nos experts pour partager avec vous notre expertise et nos réflexions
    stratégiques.`,
  titleFirstLine: "Ressources &",
  titleSecondLine: "Publications",
  subTitle: "Veille et conseils d'experts",
}

const Blog = ({ data, location }) => {
  useEffect(() => {
    const currentTheme = localStorage.getItem("theme")
    const currentLevel = localStorage.getItem("level")
    const currentType = localStorage.getItem("type")
    const currentSearch = localStorage.getItem("search")
    const currentPagination = localStorage.getItem("page")
    if (currentTheme) {
      setTheme(currentTheme)
    }
    if (currentLevel) {
      setLevel(currentLevel)
    }
    if (currentType) {
      setType(currentType)
    }
    if (currentSearch) {
      setSearch(currentSearch)
    }
    if (currentPagination) {
      setNumberPage(Number(currentPagination))
    }
  }) // Only re-run if value changes

  const [theme, setTheme] = useState("Tous")
  const [level, setLevel] = useState("Tous")
  const [type, setType] = useState("Tous")
  const [search, setSearch] = useState("")
  const [isInputShowed, setisInputShowed] = useState(false)
  const [numberPage, setNumberPage] = useState(0)

  const handleClickTheme = (value, callback) => {
    localStorage.setItem("theme", value)
    localStorage.setItem("page", 0)
    setTheme(value)
    setNumberPage(0)
    callback()
  }

  const handleClickLevel = (value, callback) => {
    localStorage.setItem("level", value)
    localStorage.setItem("page", 0)
    setLevel(value)
    setNumberPage(0)
    callback()
  }

  const handleClickType = (value, callback) => {
    localStorage.setItem("type", value)
    localStorage.setItem("page", 0)
    setType(value)
    setNumberPage(0)
    callback()
  }

  const handleSearch = event => {
    localStorage.setItem("search", event.target.value)
    localStorage.setItem("page", 0)
    setSearch(event.target.value)
    setNumberPage(0)
  }

  const handleClickSearch = () => {
    if (isInputShowed) {
      document.getElementById("search").blur()
      setSearch("")
    } else {
      document.getElementById("search").focus()
    }
    setisInputShowed(!isInputShowed)
  }

  const handleClickPage = number => {
    // Get to the top of the list with a smooth animation
    const targetTop =
      document.getElementById("articles-liste").getBoundingClientRect().top -
      100
    localStorage.setItem("page", number)
    setNumberPage(number)
    window.scrollBy({
      top: targetTop,
      left: 0,
      behavior: "smooth",
    })
  }

  const getDataArticles = data => {
    const dataFiltered = data
      .filter(item =>
        theme !== "Tous" ? item.node.acf.theme_article === theme : item
      )
      .filter(item =>
        level !== "Tous" ? item.node.acf.niveau_article === level : item
      )
      .filter(item =>
        type !== "Tous" ? item.node.acf.type_article === type : item
      )
      .filter(item =>
        search.length > 0
          ? item.node.acf.titre_article
              .toLowerCase()
              .includes(search.toLowerCase()) ||
            item.node.acf.intro_article
              .toLowerCase()
              .includes(search.toLowerCase())
          : item
      )
      .sort(
        (a, b) =>
          getDateFormat(b.node.acf.date_de_publication) -
          getDateFormat(a.node.acf.date_de_publication)
      )
    return dataFiltered
  }

  const { blog, articles, bloc_contact } = data

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Ressources"
      locationGTM={location.href}
    >
      <SEO
        title={blog.acf.title}
        description={blog.acf.meta_description}
        image={blog.acf.image}
        lang="fr"
        url={location.pathname}
      />
      <BannerV2
        hasBreadCrumb
        hasSlider
        bannerLeftCardContent={bannerLeftCardContent}
        articles={articles.edges.slice(Math.max(articles.edges.length - 3, 0))}
        isBlogPage
      />
      <Filter
        theme={theme}
        handleClickTheme={handleClickTheme}
        level={level}
        handleClickLevel={handleClickLevel}
        type={type}
        handleClickType={handleClickType}
        search={search}
        handleSearch={handleSearch}
        isInputShowed={isInputShowed}
        handleClickSearch={handleClickSearch}
      />
      {getDataArticles(articles.edges).length > 0 ? (
        <BlogList
          data={getDataArticles(articles.edges)}
          articles={articles.edges}
          numberPage={numberPage}
          handleClickPage={handleClickPage}
        />
      ) : (
        <ResultText>
          Il n'y a pas d'article correspondant à votre recherche
        </ResultText>
      )}
      <Contact
        title={bloc_contact.acf.titre_contact}
        text={bloc_contact.acf.texte_contact}
        location={location.href}
      />
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    blog: wordpressPage(id: { eq: "671d7fa0-97e6-546b-b153-04ae41950c4d" }) {
      acf {
        title
        meta_description
        image
        titre_banniere
        texte_banniere
        hashtag
        image_banniere_new {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
    articles: allWordpressPost {
      edges {
        node {
          path
          acf {
            type_article
            theme_article
            niveau_article
            titre_article
            intro_article
            date_de_publication
            vignette_article {
              localFile {
                childImageSharp {
                  fluid(srcSetBreakpoints: 400, maxWidth: 400) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            temps_de_lecture
          }
        }
      }
    }
    bloc_contact: wordpressAcfBlocs(
      id: { eq: "76393d63-8408-5238-b201-7f73d6fe7a78" }
    ) {
      acf {
        titre_contact
        texte_contact
      }
    }
  }
`
