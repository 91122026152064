import React from "react"
import styled from "styled-components"

import { NextArrow, PrevArrow } from "../ArrowCarousel"

const WrapperPagination = styled.ul`
  flex: 0 0 100%;
  margin-left: 0;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  align-items: center;
  justify-content: flex-end;
`

const PageNumber = styled.li`
  width: 30px;
  text-align: center;
  list-style: none;
  color: ${props => (props.isActive ? props.theme.primary : "#a6a6a6")};
  font-weight: bold;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.secondary};
  }
`

const Pagination = ({ allItems, handleClickPage, numberPage }) => {
  return (
    <WrapperPagination>
      <PageNumber
        onClick={() => handleClickPage(numberPage === 0 ? 0 : numberPage - 1)}
      >
        <PrevArrow
          gris
          style={{
            height: "15px",
          }}
        />
      </PageNumber>
      {Array.from(Array(allItems.length).keys()).map((item, index) => (
        <PageNumber
          key={index}
          onClick={() => handleClickPage(item)}
          isActive={numberPage == item}
        >
          {item + 1}
        </PageNumber>
      ))}
      <PageNumber
        onClick={() =>
          handleClickPage(
            numberPage === allItems.length - 1
              ? allItems.length - 1
              : numberPage + 1
          )
        }
      >
        <NextArrow
          gris
          style={{
            transform: "rotate(180deg)",
            height: "15px",
          }}
        />
      </PageNumber>
    </WrapperPagination>
  )
}

export default Pagination
