import React from "react"
import styled from "styled-components"

import Container from "../elements/Container"
import Input from "../elements/Input"
import Fleche from "../../images/icons/fleche-blanche.svg"
import Recherche from "../../images/icons/recherche.svg"
import Dropdown2 from "../elements/Dropdown/index2"
import globalVariables from "../globalVariables"

import { rotate } from "../elements/Keyframes"

const Section = styled.section`
  background-color: ${props => props.theme.secondary};
`

const Wrapper = styled.div`
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: ${globalVariables.heightFilterMobileBlog};
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: ${globalVariables.heightFilter};
  }
`

const Select = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  padding: 1rem 0;
  font-size: 14px;
  img {
    transition: all 0.2s ease-in-out;
    transform: ${props => (props.isOpen ? "rotate(90deg)" : "rotate(270deg)")};
  }
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 150px;
    padding: 0;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    min-width: 220px;
  }
`

const Arrow = styled.img`
  margin-left: 30px;
  width: 8px;
`

const Space = styled.div`
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 100%;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 200%;
      height: 1px;
      transform: translateX(-50%);
      background-color: rgba(255, 255, 255, 0.5);
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-left: 60px;
  }
`

const Search = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div {
    height: 37px;
    border-radius: 3px;
    margin-right: 0.5rem;
    padding: 18px 15px;
  }
  z-index: 2;
  @media (max-width: ${globalVariables.maxMobile}) {
    width: 100%;
    padding: 1rem 0;
    order: 2;
    img {
      width: 19px;
      height: 19px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    .show {
      width: 250px;
      transition: all 0.2s ease-in-out;
    }
    .show input {
      width: 250px;
      transition: all 0.2s ease-in-out;
    }
    .hide {
      width: 0px;
      padding: 0;
      border: none;
      transition: all 0.2s ease-in-out;
    }
    .hide input {
      width: 0px;
      padding: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  @media (min-width: ${globalVariables.bigDesktop}) {
    .show,
    .show input {
      width: 350px;
    }
  }
`
const Image = styled.img`
  cursor: pointer;
  &:hover {
    animation: ${rotate} 0.5s;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (max-width: ${globalVariables.maxMobile}) {
    order: 1;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    width: auto;
    flex-direction: row;
    align-items: center;
  }
`

const themeOptions = [
  "SEO",
  "SEA",
  "Social Ads",
  "UX",
  "CRO",
  "Web Analytics",
  "Data",
  "Autre",
]

const typeOptions = ["Article", "Actualité", "Dossier"]
const levelOptions = ["Débutant", "Expert"]

const Filter = ({
  theme,
  handleClickTheme,
  level,
  handleClickLevel,
  type,
  handleClickType,
  search,
  handleSearch,
  isInputShowed,
  handleClickSearch,
}) => {
  return (
    <Section id="filter">
      <Container>
        <Wrapper>
          <Search>
            <Input
              type="text"
              value={search}
              handleChange={event => handleSearch(event)}
              name="search"
              id="search"
              className={isInputShowed ? "show" : "hide"}
              placeholder="Recherche par mots clés"
            />
            <Image src={Recherche} onClick={handleClickSearch} />
          </Search>
          <Row>
            <Space>
              <Dropdown2
                top="2.5rem"
                left="-20px"
                triggerComponent={({ handleClick, isOpen }) => {
                  return (
                    <Select onClick={handleClick} isOpen={isOpen}>
                      {type === "Tous" ? "Tous types d'article" : type}
                      <Arrow src={Fleche} alt="choisir un type d'article" />
                    </Select>
                  )
                }}
                items={typeOptions}
                value={type}
                handleClickSelect={handleClickType}
              />
            </Space>
            <Space>
              <Dropdown2
                top="2.5rem"
                left="-20px"
                triggerComponent={({ handleClick, isOpen }) => {
                  return (
                    <Select onClick={handleClick} isOpen={isOpen}>
                      {theme === "Tous" ? "Toutes les thématiques" : theme}
                      <Arrow src={Fleche} alt="choisir une thématique" />
                    </Select>
                  )
                }}
                items={themeOptions}
                value={theme}
                handleClickSelect={handleClickTheme}
              />
            </Space>
            <Space>
              <Dropdown2
                top="2.5rem"
                left="-20px"
                triggerComponent={({ handleClick, isOpen }) => {
                  return (
                    <Select onClick={handleClick} isOpen={isOpen}>
                      {level === "Tous" ? "Tous les niveaux" : level}
                      <Arrow src={Fleche} alt="choisir un niveau" />
                    </Select>
                  )
                }}
                items={levelOptions}
                value={level}
                handleClickSelect={handleClickLevel}
              />
            </Space>
          </Row>
        </Wrapper>
      </Container>
    </Section>
  )
}

export default Filter
